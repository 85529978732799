import React from 'react';
import * as classes from './DevopsVerticalsCard.module.css';

function DevopsVerticalsCard({index, title, description, logos}) {
  return (
    <div key={index} className={classes.card}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description} </div>
      <div className={classes.logos}>
        {logos.map((logo, index) => (
          <div key={index} className={classes.logoContainer}>
            <img
              width={50}
              className="img-fluid"
              key={`logo-${logo.src}`}
              src={require(`../../../../assets/images/connect/devops/${logo.src}`).default}
              alt={logo.title}
            />
            <div className={classes.logoTitle}>{logo.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DevopsVerticalsCard;
